<template>
  <v-dialog v-model="isOpenModel" :rtl="true" :width="width" scrollable>
    <template #activator="{ props: propsDialouge }">
      <div v-if="!withVmodel">
        <slot name="activator" :props="propsDialouge" />
      </div>
    </template>
    <template #default>
      <v-card variant="outlined" color="transparent" class="relative">
        <v-locale-provider :rtl="useIsRtl()">
          <div
            :data-theme="routeTheme"
            class="custom-text-bg p-1"
          >
            <slot name="content" :is-open="isOpen" :close="close" />
          </div>
        </v-locale-provider>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
const isOpen = ref(false)
const route = useRoute()

const routeTheme = computed(() => {
  return route.path.startsWith('/delivering') ? 'delivering' : 'shopping'
})
function close() {
  props.withVmodel ? emit('update:modelValue', false) : (isOpen.value = false)
}
const emit = defineEmits(['update:modelValue'])
const isOpenModel = computed({
  get() {
    return props.withVmodel ? props.modelValue : isOpen.value
  },

  set(value) {
    props.withVmodel ? emit('update:modelValue', value) : (isOpen.value = value)
  }
})
const props = withDefaults(
  defineProps<{
    width: string
    withVmodel?: boolean
    modelValue?: boolean
  }>(),
  {
    withVmodel: false,
    width: 'auto'
  }
)
</script>
<style scoped>
.custom-text-bg {
  @apply bg-bg_color text-text_color !important;
}
/* .v-card--variant-elevated,
.v-card--variant-flat {
  background: transparent;
  color: transparent;
  @apply bg-bg_color text-text_color !important;
} */
</style>
